import { Container, Col, Row } from "react-bootstrap"
import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import burger_bistro  from "../assets/img/projects/burger-bistro.png";
import alchemy  from "../assets/img/projects/alchemy.png";
import animations from "../assets/img/projects/animations.png";


export const Projects = () => {
    
      return (
        <section className="projects" id="projects">
             <Container>
                <h1>
                  <center>My Work</center>
                </h1>
                <p>As a Pega Developer with experience in government contracts requiring security clearance, I am unable to publicly share specific examples of my professional work. However, I have a strong portfolio of personal projects showcasing my skills in CSS, JavaScript, and front-end development. These include a fully functional e-commerce store, engaging short animations, and various other web applications. These projects demonstrate my ability to create intuitive and responsive user interfaces, reflecting my dedication to both functionality and design.</p>
               
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Burger Bistro E-commerce Website</Accordion.Header>
                          <Accordion.Body>
                           
                              <Col>
                                <Row>
                                <p>Developed a full-stack e-commerce website for a Burger Bistro, featuring the following technologies and functionalities:</p>
                                <b>Back-end:</b>
                                  <li>REST API</li>
                                  <li>PHP</li>
                                  <li>MySQL</li>
                                  <br></br>
                                <b>Front-end:</b>
                                  <li>HTML/CSS</li>
                                  <li>Javascript</li>
                                  <li>Animations</li>
                                  <br></br>
                                <b>Features:</b>
                                  <li>User Authentication</li>
                                  <li>PayPal API Integration</li>
                                  <li>Sessions Management</li>
                                  <li>Add/Remove Items to Cart</li>
                                  </Row>
                                  <Row>
                                  <div className="project-btn-bx">
                                    <a href="https://joyloruth.com/BurgerBistro-Ecommerce/" className="proj-btn" id="proj-launch" target="_blank"><i class="bi bi-rocket-takeoff"></i></a>
                                    <a href="https://github.com/joyloruth/BurgerBistro-Ecommerce" className="proj-btn" id="proj-code" target="_blank"><i class="bi bi-github"></i></a>
                                  </div>
                                 </Row>
                              </Col>
                      
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Alchemy Web Game</Accordion.Header>
                          <Accordion.Body>
                          <Col>
                              <Row>
                              <p>A web game were the objective is to combine elements to create new elements.</p>
                              <b>Front-end:</b>
                                <li>HTML/CSS</li>
                                <li>Javascript</li>
                                <li>Animations</li>
                                <li>Collision Detection</li>
                              </Row>
                              <Row>
                                <div className="project-btn-bx">
                                  <a href="https://joyloruth.github.io/AlchemyWebGame/" className="proj-btn" id="proj-launch" target="_blank" ><i class="bi bi-rocket-takeoff"></i></a>
                                  <a href="https://github.com/joyloruth/AlchemyWebGame" className="proj-btn" id="proj-code" target="_blank"><i class="bi bi-github"></i></a>
                                </div>
                              </Row>
                            </Col>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Short Animations</Accordion.Header>
                          <Accordion.Body>
                            <p>Created short interactive animations 
                              using CSS and JavaScript, purely for fun,
                               driven by my passion for crafting engaging animations.</p>
                            <b>Front-end:</b>
                              <li>HTML/CSS</li>
                              <li>Javascript</li>
                              <li>Python</li>
                            <Row>
                            <div className="project-btn-bx">
                              <a href="https://joyloruth.com/animations.html" className="proj-btn" id="proj-launch" target="_blank" ><i class="bi bi-rocket-takeoff"></i></a>
                              
                            </div>
                           </Row>
                            
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      </Container>
        </section>
      );
    }
              
              
              
       
    
    
    


  


