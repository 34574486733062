import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import banner_img from "../assets/img/banner/banner-img.png";

export const Banner = () => {
    return (
        <section className="banner" id="home">
         <Container>
            <Row className="pull-left">
              <Col xs={12} md={6} xl={7}>
                <span className="tagline">Welcome to My Portfolio</span>
                <h1 className="name">Joy LoRuth Woodard</h1>
                <p>Certified Pega developer, Programming language Polyglot, Problem solver that's Passionate about user interface and experience.  Seeking to leverage my expertise in Pega and traditional software development with my passion for UI/UX to contribute to innovative projects in a dynamic and forward-thinking organization.
 </p>
                <a href="mailto:joyloruth@gmail.com" class="banner-btn" target="_blank">Get In Touch <i class="bi bi-arrow-right-circle"></i> </a>
            </Col>
            <Col xs={12} md={6} xl={5}>
                <img src={banner_img} class="banner-img"></img>
            </Col>
            </Row>
         </Container>
        </section>
    )    
}