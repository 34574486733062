import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import html_logo  from "../assets/img/skills/html-logo.png";
import react_logo from "../assets/img/skills/react-logo.png";
import css_logo  from "../assets/img/skills/css-logo.png";
import javascript_logo from "../assets/img/skills/javascript-logo.png";
import bootstrap_logo  from "../assets/img/skills/bootstrap-logo.png";
import java_logo from "../assets/img/skills/java-logo.png";
import sql_logo  from "../assets/img/skills/sql-logo.png";


export const Skills = () => {
    
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 5
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1
            }
          };

    return(
        <section className="skill" id="skills">
         <Container>
            <Row>
             <Col>
              <div className="skill-box">
                <h1>
                    Skills
                </h1>
                <p> 
                As a skilled Pega UI Developer, I specialize in designing and implementing user interfaces using Pega PRPC and Pega frameworks. With a strong proficiency in React, JavaScript, CSS, and HTML, I excel in creating seamless, intuitive, and responsive user experiences.
                </p>
                <Carousel responsive={responsive} infinite={true} className="skill-slider">
                <div className="item">
                    <img src={java_logo} />
                    <h5>Java</h5>
                  </div>
                  <div className="item">
                    <img src={sql_logo} />
                    <h5>SQL</h5>
                  </div>
                  <div className="item">
                    <img src={react_logo} />
                    <h5>React</h5>
                  </div>
                  <div className="item">
                    <img src={javascript_logo} />
                    <h5>Javascript</h5>
                  </div>
                  <div className="item">
                    <img src={bootstrap_logo} />
                    <h5>Bootstrap</h5>
                  </div>
                  <div className="item">
                    <img src={css_logo} />
                    <h5>CSS 3</h5>
                  </div>
                  <div className="item">
                    <img src={html_logo} />
                    <h5>HTML 5</h5>
                  </div>
                  
                </Carousel>
              </div>
             </Col>
            </Row>
         </Container>
        </section>

    )
}