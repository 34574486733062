import { Container } from "react-bootstrap"
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import contact_img from "../assets/img/contact/contact-img.png";


export const Contact = () =>{
  
    
    return(
        
        <section className="contact" id="contact" >
        <Container>
            <Col>
                <h1>Get In Touch</h1>
                    <p>I'm always excited to connect with fellow professionals, potential collaborators, and anyone interested in Pega UI development. Whether you have a question, a project idea, or just want to say hello, feel free to reach out!</p>

                    
                    <div className='social-icon-container'>
                        <a href="https://www.github.com/joyloruth"  target="_blank" ><i class="bi bi-github"  alt="github icon" ></i></a>
                        <a href="https://www.linkedin.com/in/joy-loruth-woodard-b0abb9b0/"  target="_blank" ><i class="bi bi-linkedin"   alt="linkedin icon" ></i></a>
                        <a href="mailto:joyloruth@gmail.com"  target="_blank" ><i class="bi bi-envelope"   alt="email icon"    ></i></a>   
                  </div>               
            </Col>
            <img src={contact_img} class="contact-img"></img>
            <Col>
            </Col>
             
        </Container>
    </section>
    
    )
}