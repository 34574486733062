
import { Navbar } from 'react-bootstrap';
import { Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";


export const NavBar = () =>{
    return (
        <Navbar bg='dark' variant='dark' expand="lg" >
              <Container>
                <Navbar.Brand href="#home">Joy LoRuth </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ms-auto" >
                    <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#skills">Skills</Nav.Link>
                    <Nav.Link href="#projects">Projects</Nav.Link>
                    <Nav.Link href="#contact">Contact</Nav.Link>
                  </Nav>
                 
                  <a href="https://docs.google.com/document/d/e/2PACX-1vStce449oHR6bwKxgetbn_ufPgl8WCz_s2fZGLBWlE31LVJF2UoJSy8YMpbrV3HO3rLKQRjT6Y9iYSV/pub" class="standard-btn" target="_blank" >CV <i class="bi bi-file-earmark-person"></i></a>
                  
                </Navbar.Collapse>
              </Container>
            </Navbar>
          );
    }


